<template>
  <div>
    <div class="header">
      <h4>合同功能</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8" v-access data-ctrl="contract.enterCard">
        <el-card shadow="always" v-prev path="/system/contract/list">
          <el-button size="medium" type="text">我的合同</el-button>
        </el-card>
      </el-col>
      <el-col :span="8" v-access data-ctrl="contractTemplate.enterCard">
        <el-card shadow="always" v-prev path="/system/contractTemplate/list">
          <el-button size="medium" type="text"> 合同模板</el-button>
        </el-card>
      </el-col>
    </el-row>
    <!-- <div class="header">
      <h4>合同统计</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="always">
          <div slot="header" class="clearfix">
            <span>卡片名称</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >刷新</el-button
            >
          </div>
          统计图1
        </el-card>
      </el-col>
    </el-row> -->

    <div class="header">
      <h4>帮助文档</h4>
    </div>
    <el-row :gutter="12" style="margin-top: 32px">
      <el-col :span="8">
        <el-card shadow="always">
          <el-button size="medium" type="text">签署流程</el-button>
        </el-card>
      </el-col>
      <el-col :span="8">
        <a
          href="/content/kpce6tnz0/document.html?skin=document"
          target="_blank"
        >
          <el-card shadow="always">
            <el-button size="medium" type="text">签署人员印章授权</el-button>
          </el-card>
        </a>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
